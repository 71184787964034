import React from "react"
import {
  Configure,
  Hits,
  InstantSearch,
  RefinementList,
} from "react-instantsearch-dom"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hit from "../components/whats-open/Hit"
import { getSearchClient } from "../utils/algolia"

export default function Page() {
  const searchClient = getSearchClient(true)

  const labels = {
    restaurant: "Restaurant",
    "beach-club": "Beach Club",
    beach: "Beach",
    club: "Club",
    spa: "Spa",
  }

  return (
    <Layout>
      <Seo
        title="What's Open in Ibiza 2021"
        description="A live list of what's open including Restaurants, Spas and Beach Clubs"
      />
      <div className="container mx-auto py-4 px-2 lg:px-8">
        <div className="flex flex-col sm:flex-row justify-between sm:items-end">
          <h1>What's Open in Ibiza</h1>
          <h3>Ibiza's premier villa rental and experience company</h3>
        </div>
        <InstantSearch searchClient={searchClient} indexName="listings">
          <Configure hitsPerPage={100} />
          <div className="bg-gray-100 flex my-8 p-8 flex-col">
            <span>FILTER BY TYPE</span>
            <RefinementList
              attribute="type"
              transformItems={items =>
                items.map(item => ({
                  ...item,
                  label: labels[item.label] || item.label,
                }))
              }
            />
          </div>
          <div className="listings-list">
            <Hits hitComponent={Hit} />
          </div>
        </InstantSearch>
      </div>
    </Layout>
  )
}
