import SanityBlockContent from "@sanity/block-content-to-react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const labels = {
  restaurant: "Restaurant",
  "beach-club": "Beach Club",
  beach: "Beach",
  club: "Club",
  spa: "Spa",
}

const Wrapper = ({ listing, children }) =>
  listing.hidden ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <Link to={`/${listing.slug.current}`}>{children}</Link>
  )

export default function Hit({ hit: listing }) {
  console.log(listing)
  return (
    <Wrapper listing={listing}>
      <div className="font-property bg-white shadow-sm hover:shadow-xl transition duration-300 ease-in-out grid grid-cols-12 overflow-hidden h-auto md:h-full">
        <div className="col-span-12 h-56 ">
          {listing.image.asset?.url && (
            <img
              src={listing.image.asset.url}
              className="h-56  w-full object-fill"
              alt={listing.name}
            />
          )}
        </div>
        <div className="px-4 pt-2 pb-4 col-span-12">
          <div className="font-bold flex items-center justify-between">
            <div>
              <h3 className="font-property text-lg">{listing.name}</h3>

              <span className="font-property font-thin">
                {labels[listing.type] || listing.type}
              </span>
            </div>

            <div className="">
              <span className="inline-flex items-center px-2.5 py-0.5 text-sm font-medium bg-green-300 text-green-800">
                Open
              </span>
            </div>
          </div>
          <div className="py-3">
            <p className="font-light text-gray-400 text-sm  line-clamp-5 lg:line-clamp-3">
              {listing.highlight}
            </p>
          </div>
          {/* <div className="font-semibold flex items-center justify-between">
            <div>
              <div className="grid justify-items-center items-end font-sans">
                {labels[listing.type] || listing.type}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Wrapper>
  )
}
